import { DocumentTree } from "./document-tree.objects";



export class DocumentTreeLoader {

    documentTree: DocumentTree;

    constructor(nodes: any) {
        $(() => {

            this.documentTree = new DocumentTree(nodes);
        });
    }
}