

//source: https://blog.abelotech.com/posts/number-currency-formatting-javascript/
export function FormatNumberWithCommas(value: string): string {
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function Round_1Digit(data: number): any {
    return (<number>data).toFixed(1);
}

export function Round_2Digits(data: number): any {
    return (<number>data).toFixed(2);
}

export function ComputePercent(count: number, total: number): number {
    if (total == undefined || total == 0) return -1;
    else {
        let percent: number = (count / total) * 100.0;
        return percent;
    }
}
