import { plainToClass } from "class-transformer";
import { DocumentTree } from "../document-tree/document-tree.objects";


export class DocumentLibraryLoader {

    constructor(modelJson: any) {
        $(() => {

            let model: DocumentLibraryModel = plainToClass(DocumentLibraryModel, <DocumentLibraryModel>modelJson);
            model.DocumentSections = plainToClass(DocumentSection, <DocumentSection[]>model.DocumentSections);

            //console.log(model);

            model.DocumentSections.forEach((section: DocumentSection) => {
                new DocumentTree(JSON.parse(section.DocumentsJson), section.TreeId, section.SearchBoxId, section.SearchClearId);                
            });
        });
    }
}

export class DocumentLibraryModel {
    Title: string;
    Description: string;
    //Images: string[];
    DocumentSections: DocumentSection[];
}

export class DocumentSection {
    Label: string;
    DocumentsJson: string;
    TreeId: string;
    SearchBoxId: string;
    SearchClearId: string;
}