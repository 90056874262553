
//only import what we use.
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleUp, faAngleDown, faBars, faUser, faCaretRight, faExternalLinkAlt, faUserPlus, faBan, faCheck, faUserEdit,
    faEye, faEyeSlash, faKey, faUsers, faFolder, faFileExcel, faFileWord, faFilePowerpoint, faFilePdf, faFile, faFileAlt, faFileAudio, faFileVideo,
    faTimes, faSquare, faCog, faFileDownload, faQuestionCircle, faBook, faBullhorn, faChevronCircleUp, faInfo, faLock, faHome, faArrowDown,
    faChartLine, faSignInAlt, faEnvelope, faGlobe, faFemale, faLink, faFirstAid, faStar, faUserCircle, faIdBadge, faSearch, faClipboardList, faGraduationCap, faHandHoldingUsd,
    faCopy, faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

library.add(faAngleUp); library.add(faAngleDown); library.add(faBars); library.add(faUser); library.add(faCaretRight); library.add(faExternalLinkAlt);
library.add(faUserPlus); library.add(faBan); library.add(faCheck); library.add(faUserEdit); library.add(faEye); library.add(faEyeSlash);
library.add(faKey); library.add(faUsers); library.add(faFolder); library.add(faFileExcel); library.add(faFileWord); library.add(faFilePowerpoint); library.add(faFilePdf); library.add(faFile);
library.add(faTimes); library.add(faSquare); library.add(faCog); library.add(faFileAlt); library.add(faFileAudio); library.add(faFileVideo);
library.add(faFileDownload); library.add(faQuestionCircle); library.add(faBook); library.add(faBullhorn); library.add(faChevronCircleUp); library.add(faInfo); library.add(faLock); library.add(faHome); library.add(faArrowDown);
library.add(faChartLine); library.add(faSignInAlt); library.add(faEnvelope); library.add(faGlobe); library.add(faFemale); library.add(faLink); library.add(faFirstAid); library.add(faStar); library.add(faUserCircle); library.add(faIdBadge); library.add(faSearch); library.add(faClipboardList); library.add(faGraduationCap); library.add(faHandHoldingUsd);
library.add(faCopy); library.add(faExclamationCircle);

dom.watch();